body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* scroll-behavior: smooth; */
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#fp-nav {
  z-index: 1;
  right: 39px !important;
}
#fp-nav.invisible {
  display: none;
}
#fp-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 150px;
}

#fp-nav ul li a span {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 2px solid white !important;
  box-sizing: content-box;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
}

#fp-nav ul li a:hover span {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 2px solid white !important;
  box-sizing: content-box;
  margin: 0 !important;
}

#fp-nav ul li a.active span {
  background: white !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 !important;
  /* box-sizing: content-box; */
}

#fp-nav.black ul li a span {
  border: 2px solid black !important;
}

#fp-nav.black ul li a:hover span {
  border: 2px solid black !important;
}

#fp-nav.black ul li a.active span {
  background: black !important;
}
