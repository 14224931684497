@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Black.eot');
    src: url('./Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('./Montserrat-Black.woff2') format('woff2'),
        url('./Montserrat-Black.woff') format('woff'),
        url('./Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Light.eot');
    src: url('./Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('./Montserrat-Light.woff2') format('woff2'),
        url('./Montserrat-Light.woff') format('woff'),
        url('./Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Bold.eot');
    src: url('./Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Montserrat-Bold.woff2') format('woff2'),
        url('./Montserrat-Bold.woff') format('woff'),
        url('./Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Regular.eot');
    src: url('./Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Montserrat-Regular.woff2') format('woff2'),
        url('./Montserrat-Regular.woff') format('woff'),
        url('./Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('./HelveticaNeueCyr-BoldItalic.eot');
    src: url('./HelveticaNeueCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./HelveticaNeueCyr-BoldItalic.woff2') format('woff2'),
        url('./HelveticaNeueCyr-BoldItalic.woff') format('woff'),
        url('./HelveticaNeueCyr-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('./HelveticaNeueCyr-Light.eot');
    src: url('./HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
        url('./HelveticaNeueCyr-Light.woff2') format('woff2'),
        url('./HelveticaNeueCyr-Light.woff') format('woff'),
        url('./HelveticaNeueCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('./HelveticaNeueCyr-LightItalic.eot');
    src: url('./HelveticaNeueCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./HelveticaNeueCyr-LightItalic.woff2') format('woff2'),
        url('./HelveticaNeueCyr-LightItalic.woff') format('woff'),
        url('./HelveticaNeueCyr-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('./HelveticaNeueCyr-Bold.eot');
    src: url('./HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('./HelveticaNeueCyr-Bold.woff2') format('woff2'),
        url('./HelveticaNeueCyr-Bold.woff') format('woff'),
        url('./HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

